import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewDocumentSvg = _resolveComponent("NewDocumentSvg")!
  const _component_menu_link = _resolveComponent("menu-link")!
  const _component_ArchiveSvg = _resolveComponent("ArchiveSvg")!
  const _component_ReportSvg = _resolveComponent("ReportSvg")!
  const _component_UploadSvg = _resolveComponent("UploadSvg")!
  const _component_ReleaseNotesSvg = _resolveComponent("ReleaseNotesSvg")!
  const _component_arrow_back = _resolveComponent("arrow-back")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sidebar", { opened: _ctx.isOpened }])
  }, [
    _createVNode(_component_menu_link, {
      text: "EPX Engagements",
      url: "/"
    }, {
      icon: _withCtx(() => [
        _createVNode(_component_NewDocumentSvg)
      ]),
      _: 1
    }),
    _createVNode(_component_menu_link, {
      text: "Archives",
      url: "/archive"
    }, {
      icon: _withCtx(() => [
        _createVNode(_component_ArchiveSvg)
      ]),
      _: 1
    }),
    _createVNode(_component_menu_link, {
      text: "Report Dashboard",
      url: "/report"
    }, {
      icon: _withCtx(() => [
        _createVNode(_component_ReportSvg)
      ]),
      _: 1
    }),
    _createVNode(_component_menu_link, {
      text: "Bulk Behaviors",
      url: "/upload"
    }, {
      icon: _withCtx(() => [
        _createVNode(_component_UploadSvg)
      ]),
      _: 1
    }),
    _createVNode(_component_menu_link, {
      text: "Release notes",
      url: "/release-notes"
    }, {
      icon: _withCtx(() => [
        _createVNode(_component_ReleaseNotesSvg)
      ]),
      _: 1
    }),
    (_ctx.isLogInEnabled)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: "log-out-button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
        }, "Log Out"))
      : _createCommentVNode("", true),
    (_ctx.isOpened)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "close-sidebar-menu",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onCloseSidebar'))),
          onKeydown: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.$emit('onCloseSidebar')), ["enter"]))
        }, [
          _createVNode(_component_arrow_back)
        ], 32))
      : _createCommentVNode("", true)
  ], 2))
}