
import CienaLogo from '@/assets/img/ciena-logo.vue';
import Hamburger from '@/assets/img/hamburger.vue';
import SearchInputHeader from '@/components/SearchInputHeader.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TheHeader',
  components: {
    CienaLogo,
    SearchInputHeader,
    Hamburger,
  },
  props: {
    isOpened: {
      type: Boolean,
      required: true,
    },
    noSearch: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['onSearchBarSubmit', 'onOpenSidebar'],
  methods: {
    async login() {
      // await this.$auth.signInWithRedirect();
    },
  },
});
