import { createStore, Store } from 'vuex';
import { InjectionKey } from 'vue'
import {Category} from "@/api/categoryApi";
import {GetQuizRequestPayload} from '@/api/QuizApi';
import Question from '@/components/Question/Question.vue';
import {IQuestion} from '@/types/question';

export const key: InjectionKey<Store<State>> = Symbol()
export interface DeleteQuestion {
  isDeleteQuestionOpen: boolean,
  question: null | IQuestion,
  questionPositionIndex: null | number,
  isSaveNowQuestionLocationOpen: boolean,
  quizIds: Array<number>,
}
export interface EditQuestion {
  isEditQuestionOpen: boolean,
  question: null | IQuestion,
  quizIds: Array<number>,
}
export interface State {
  isAuthenticated: boolean;

  categories: Array<Category>;
  currentCategory: null | number;
  isEditingCategory: boolean;
  isQuestionsEditMode: boolean;

  getQuizPayload: GetQuizRequestPayload,

  createQuestion: {
    isCreateQuestionInitiated: boolean,
    question: null | IQuestion,
    questionPositionIndex: null | number,
    isSelectingMode: boolean,
    isSaveNowQuestionLocationOpen: boolean,
    quizIds: Array<number>,
  },
  deleteQuestion: DeleteQuestion,
  editQuestion: EditQuestion
  keep_answer: boolean | null,
}
const store = createStore<State>({
  state: {
    isAuthenticated: false,
    categories: [],
    currentCategory: null,
    isEditingCategory: false,

    isQuestionsEditMode: false,

    getQuizPayload: {
      page: 1,
      name: '',
      sort_by: 'created_at',
      sort_dir: 'desc',
      filter: {
        question_template_ids: {},
        question_ids: {}
      }
    },
    createQuestion: {
      isCreateQuestionInitiated: false,
      question: null,
      questionPositionIndex: null,
      isSelectingMode: false,
      isSaveNowQuestionLocationOpen: false,
      quizIds: [],
    },
    deleteQuestion: {
      isDeleteQuestionOpen: false,
      question: null,
      questionPositionIndex: null,
      isSaveNowQuestionLocationOpen: false,
      quizIds: [],
    },
    editQuestion: {
      isEditQuestionOpen: false,
      question: null,
      quizIds: [],
    },

    keep_answer: null,
  },
  mutations: {
    setIsAuthenticated(state, isAuthenticated: boolean) {
      state.isAuthenticated = isAuthenticated;
    },
    setCategories(state, payload: Category[]) {
      state.categories = payload;
    },
    setIsEditingCategory(state, payload: boolean) {
      state.isEditingCategory = payload;
    },
    setCurrentCategory(state, payload:  null | number) {
      state.currentCategory = payload;
    },

    setQuestionEditMode(state, payload: boolean) {
      state.isQuestionsEditMode = payload;
    },
    setQuizPayload(state, payload: GetQuizRequestPayload){
      state.getQuizPayload = payload;
    },
    setInitiateCreateQuestion(state, payload: boolean) {
      state.createQuestion.isCreateQuestionInitiated = payload
    },
    setCreatedQuestionInitialState(state) {
      state.createQuestion = {
        isCreateQuestionInitiated: false,
        question: null,
        isSelectingMode: false,
        isSaveNowQuestionLocationOpen: false,
        quizIds: [],
        questionPositionIndex: null,
      }
    },
    setCreatedQuestion(state, payload: IQuestion, ) {
      state.createQuestion.question = payload;
    },
    setCreatedQuestionIndex(state, payload: number) {
      state.createQuestion.questionPositionIndex = payload;
    },
    setOpenSaveNowQuestionLocationOpen(state, payload: boolean) {
      state.createQuestion.isSaveNowQuestionLocationOpen = payload
    },
    quizIdsAdd(state, payload: Array<number>) {
      payload.forEach(quizId => {
        const elementPosition = state.createQuestion.quizIds.indexOf(quizId);
        if(elementPosition === -1) {
          state.createQuestion.quizIds.push(quizId);
        }
      })
    },
    quizIdsOmit(state, payload: Array<number>) {
      payload.forEach(quizId => {
        const elementPosition = state.createQuestion.quizIds.indexOf(quizId);
        if(elementPosition !== -1) {
          state.createQuestion.quizIds.splice(elementPosition, 1);
        }
      })
    },
    quizIdsToggle(state, payload: number) {
      const elementPosition = state.createQuestion.quizIds.indexOf(payload);
      if(elementPosition !== -1) {
        state.createQuestion.quizIds.splice(elementPosition, 1);
      } else {
        state.createQuestion.quizIds.push(payload);
      }
    },
    quizIdsSet(state, payload: Array<number>) {
      state.createQuestion.quizIds = payload;
    },

    setInitiateDeleteQuestion(state, payload: DeleteQuestion) {
      state.deleteQuestion = payload
    },
    setInitiateEditQuestion(state, payload: EditQuestion) {
      state.editQuestion = payload
    },
    setKeepAnswer(state, payload: boolean | null) {
      state.keep_answer = payload;
    },
  },
  actions: {
  },
});
export default store
